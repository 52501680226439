* {
    margin: 0%;
    padding: 0%;
    font-family: 'Covered By Your Grace';
    font-style: normal;
    font-weight: normal;
}

html {
    background-color: #EFF1DE;
  }

.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100%; */
}