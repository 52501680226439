.ImageContainer {
    background-image: url("../Images/background.JPG");
    background-size: 105%;
    background-position: -25px bottom;
    /* margin-top: 3rem; */
    background-repeat: no-repeat;
    width: 105%;
    height: 275px;
    mix-blend-mode: hard-light;
    backdrop-filter: blur(4px);
}

.DesktopImageContainer {
    background-image: url("../Images/backgroundTransperant.png");
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    margin-top: -5rem;
    width: 105%;
    height: 50vw;
    mix-blend-mode: hard-light;
    backdrop-filter: blur(4px);
}