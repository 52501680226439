.bottom {
    width: 100vw;
    height: 400px;
    padding: 0px;
    margin: 0px;
}

.Mountains-Mobile {
    margin-top: 2rem;
    width: 100vw;
    background-image: url('../Images/Mountains&Trees.png');
    background-size: 100%;
    background-repeat: no-repeat;
    height: 60vw;
    position: relative;
}

.Information {
    background: linear-gradient(180deg, #D6D6D6 0%, #EFF1DE 100%);
    text-align: center;
}

#NameAJ {
    font-size: 64px;
    text-align: center;
    color: #5C5C5C;
}

#WeddingDate, #DaysLeft {
    font-size: 24px;
    color: #5C5C5C;
}

.rsvpBtn {
    cursor: pointer;
    margin-top: 50px;
    font-size: 36px;
    color: #5c5c5c;
    background-color: transparent;
    border: none;
}

/* Desktop */
.Mountains-Desktop {
    margin-top: 5rem;
    width: 100vw;
    background-image: url('../Images/Mountains&TreesDesktop.png');
    background-size: 100%;
    background-repeat: no-repeat;
    height: 60vw;
    position: relative;
    z-index: -1;
}

.Information-Desktop {
    text-align: center;
    margin-top: -63%;
    z-index: 2;
}

#NameAJ-Desktop {
    font-style: normal;
    font-weight: normal;
    font-size: 96px;
    line-height: 129px;
    text-align: center;
    color: #5C5C5C;
}

#WeddingDate-Desktop, #DaysLeft-Desktop {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 48px;
    text-align: center;

    color: #5C5C5C;
}