.EndOfPage {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    font-size: 14px;
    color: #5c5c5c;
    margin-top: 200px;
    margin-bottom: 20px;

}