.Title {
    margin: 2.5% 0%;
    background-color: rgba(245, 245, 245, 0.25);
    color: #5c5c5c;
    border: none;
    width: 100%;
    height: 2rem;
    font-size: 36px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

}