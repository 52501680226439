.hamMenu {
  position: relative;
  right: 50%;
  padding-bottom: 2%;
  padding-top: 0%;
  margin-top: -2rem;
}

#menuToggle {
  display: block;
  position: relative;
  top: 45px;
  left: 30px;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;

  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #5c5c5c;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
  * But let's hide the middle one.
  */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
  * Ohyeah and the last one should go the other direction
  */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
  * Make this absolute positioned
  * at the top left of the screen
  */
#menu {
  position: absolute;
  width: 20rem;
  height: 50rem;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  display: flex;
  flex-direction: column;
  background: rgba(245, 245, 245, 1);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
  width: 100%;
}

/*
  * And let's slide it in from the left
  */
#menuToggle input:checked ~ ul {
  transform: none;
}

/* Desktop Nav Bar Styling */
#menu-Desktop {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 32px;
  text-align: center;

  color: #5c5c5c;
  z-index: 3;
}

#menu-Desktop li {
  cursor: pointer;
  padding: 10px;
  width: 100%;
}
